<template>
  <validation-provider
    :mode="vmode"
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${id}`"
      :label="label"
      :label-for="`input-${id}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid),
      }"
    >
      <b-form-textarea
        :id="`input-${id}`"
        :name="`input-${id}`"
        v-model="inputVal"
        :state="getValidationState({ dirty, validated, valid, errors })"
        :aria-describedby="`input-${id}-live-feedback`"
        :rows="rows"
        :max-rows="maxrows"
        @blur="onBlur"
        :disabled="disabled"
        :placeholder="placeholder"
      ></b-form-textarea>

      <b-form-invalid-feedback :id="`input-${id}-live-feedback`">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: String,
    vid: String,
    name: String,
    label: String,
    placeholder: String,
    rows: {
      default: "2",
      type: String,
    },
    maxrows: {
      default: "4",
      type: String,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    vmode: {
      type: String,
      default: "aggressive",
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  // watch: {
  //     value() {
  //         this.$emit('input', this.value);
  //     }
  // },
  methods: {
    // getValidationState({ dirty, validated, valid = null }) {
    //     return dirty || validated ? valid : null;
    // },
    getValidationState({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return false;
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? null
        : dirty || validated
        ? valid
        : null;
    },
    onBlur(e) {
      console.debug("textarea onBlur");
      this.$emit("blur", e);
    },
  },
};
</script>
